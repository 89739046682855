import smartlockApi from '@/api/smartlock'
import { UserKey, UserItem } from '@/model/model'

const state = {
  deviceIds: [],
  users: [] as UserItem[]
}

const mutations = {
  // setDeviceIds(state, val) {
  //   state.deviceIds = val
  // },
  setUsers(state, val) {
    state.users = val
  },
}


const actions = {
  async getUserDevices ({ commit, state }) {
    const rtnService = await smartlockApi.getUserDevicesV1()

    const rtn = rtnService.data.filter(val => val.withKeypad)

    console.log('users.getUserDevices rtn.data', rtn)

    commit('devices/setDevices', rtn, { root: true })
  },
  async listUsers ({ commit, state, rootState }) {
    let rtn

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.listUsersV2(rootState.auth.client.clientId)
    } else {
      rtn = await smartlockApi.listUsersV1()
    }

    console.log('users.listUsers rtn.data', rtn.data)

    commit('setUsers', rtn.data)
  },
  async createUsers({ commit, state, rootState }, users) {

    console.log('users.createUsers!!!!!', users)

    let rtn

    const userItems: UserItem[] = users.map(user => {
      
      const userItem: UserItem = {
        clientId: rootState.auth.client.clientId,
        clientName: rootState.auth.client.clientName,
        username: user.username,
        password: user.password,
        roles: user.roles
      }

      return userItem
    })

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.createUsersV2(userItems)

    } else {

      rtn = await smartlockApi.createUsersV1(userItems)
    }

    console.log('users.createUsers', rtn.data)

    return rtn.data
  },
  async deleteUsers({ commit, state, rootState }, users) {

    console.log('users.deleteUsers!!!!!', users)

    let rtn

    const userKeys: UserKey[] = users.map(user => {

      const userKey: UserKey = {
        clientId: rootState.auth.client.clientId,
        username: user.username
      }

      return userKey
    })

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.deleteUsersV2(userKeys)

    } else {

      rtn = await smartlockApi.deleteUsersV1(userKeys)
    }


    console.log('users.deleteUsers', rtn)
  },
  async updateUsers({ commit, state, rootState }, users) {

    console.log('users.updateUsers!!!!!', users)

    let rtn

    const userItems: UserItem[] = users.map(user => {
      
      const userItem: UserItem = {
        clientId: rootState.auth.client.clientId,
        clientName: rootState.auth.client.clientName,
        username: user.username,
        password: user.password,
        deviceIds: user.deviceIds,
        roles: user.roles,
      }

      return userItem
    })

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.updateUsersV2(userItems)

    } else {

      rtn = await smartlockApi.updateUsersV1(userItems)
    }

    console.log('users.updateUsers', rtn.data)

    return rtn.data
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

