import * as qs from 'qs'
import { default as axios } from 'axios'
import { OfflineItem, UserKey, UserItem } from '@/model/model'
import { axiosInterceptor } from '@/helpers'

const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 8 * 1e3,
});

const resourceClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 8 * 1e3,
});

resourceClient.interceptors.request.use(axiosInterceptor);

export default {

  async deleteOfflineV1(deviceId: string) : Promise<any> {

    const rtn = await resourceClient.delete(`/v1/devices/${deviceId}/passwords/offline`)
      .catch(err => {
        console.log(`deleteOfflineV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`deleteOfflineV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async deleteOfflineV2(clientId: string, deviceId: string) : Promise<any> {

    const rtn = await resourceClient.delete(`/v2/${clientId}/${deviceId}/offline`)
      .catch(err => {
        console.log(`deleteOfflineV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`deleteOfflineV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async deleteClientV2(clientId: string) : Promise<any> {

    const rtn = await resourceClient.delete(`/v2/clients/${clientId}`)
      .catch(err => {
        console.log(`deleteClientV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`deleteClientV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async deleteUsersV1(userKeys: UserKey[]) : Promise<any> {

    const rtn = await resourceClient.delete(`/v1/users`, {data: userKeys})
      .catch(err => {
        console.log(`deleteUsersV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`deleteUsersV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async deleteUsersV2(userKeys: UserKey[]) : Promise<any> {

    const rtn = await resourceClient.delete(`/v2/users`, {data: userKeys})
      .catch(err => {
        console.log(`deleteUsersV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`deleteUsersV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async createUsersV1(userItems: UserItem[]) : Promise<any> {

    const rtn = await resourceClient.put(`/v1/users`, userItems)
      .catch(err => {
        console.log(`createUsersV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`createUsersV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async createUsersV2(userItems: UserItem[]) : Promise<any> {

    const rtn = await resourceClient.put(`/v2/users`, userItems)
      .catch(err => {
        console.log(`createUsersV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`createUsersV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async updateUsersV1(userItems: UserItem[]) : Promise<any> {

    const rtn = await resourceClient.post(`/v1/users`, userItems)
      .catch(err => {
        console.log(`updateUsersV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`updateUsersV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async updateUsersV2(userItems: UserItem[]) : Promise<any> {

    const rtn = await resourceClient.post(`/v2/users`, userItems)
      .catch(err => {
        console.log(`updateUsersV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`updateUsersV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async listUsersV1() : Promise<any> {

    const rtn = await resourceClient.get(`/v1/users`)
      .catch(err => {
        console.log(`listUsersV1 err ${JSON.stringify(err)}`)
        throw err
      })

    // console.log(`listUsersV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async listUsersV2(clientId: string) : Promise<any> {

    const rtn = await resourceClient.get(`/v2/${clientId}/users`)
      .catch(err => {
        console.log(`listUsersV2 err ${JSON.stringify(err)}`)
        throw err
      })

    // console.log(`listUsersV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async getUserDevicesV1() : Promise<any> {

    const rtn = await resourceClient.get(`/v1/user/devices`)
      .catch(err => {
        console.log(`getUserDevicesV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`getUserDevicesV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async getUserV1() : Promise<any> {

    const rtn = await resourceClient.get(`/v1/user`)
      .catch(err => {
        console.log(`getUser err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`getUser ${JSON.stringify(rtn)}`)

    return rtn

  },
  async revoke(refreshToken: string) : Promise<any> {

    const rtn = await resourceClient.post(`/auth/revoke`, {refreshToken})
      .catch(err => {
        console.log(`revoke err ${JSON.stringify(err)}`)
        throw err
      })

    return rtn

  },
  async authorize(clientName: string, userGroup: string) : Promise<any> {

    const rtn = await authClient.post(`/auth/authorize`, {clientName, userGroup}, { headers: { 'X-API-KEY': process.env.VUE_APP_API_KEY }})
      .catch(err => {
        console.log(`authorize err ${JSON.stringify(err)}`)
        throw err
      })

    return rtn

  },
  async addClientV2(deviceId: string, clientName: string, tuyaAccount: string, isPublic: boolean) : Promise<any> {

    const rtn = await resourceClient.put(`/v2/clients`, {deviceId, clientName, tuyaAccount, isPublic})
      .catch(err => {
        console.log(`addClient err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`addClient ${JSON.stringify(rtn)}`)

    return rtn

  },
  async getClientV1(clientId: string) : Promise<any> {

    console.log(`getClientV1`)

    const rtn = await resourceClient.get(`/v1/clients/${clientId}`)
      .catch(err => {
        console.log(`getClientV1 err ${JSON.stringify(err)}`)
        throw err;
      })

    return rtn

  },
  async listClientsV2() : Promise<any> {

    const rtn = await resourceClient.get(`/v2/clients`)
      .catch(err => {
        console.log(`listClients err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`listClients ${JSON.stringify(rtn)}`)

    return rtn

  },
  async listDevicesV1() : Promise<any> {

    console.log(`listDevicesV1`)

    const rtn = await resourceClient.get(`/v1/devices`)
      .catch(err => {
        console.log(`listDevicesV1 err ${JSON.stringify(err)}`)
        throw err;
      })

    return rtn

  },
  async listDevicesV2(clientId: string) : Promise<any> {

    console.log(`listDevicesV2 clientId ${clientId}`)

    const rtn = await resourceClient.get(`/v2/${clientId}/devices`)
      .catch(err => {
        console.log(`listDevicesV2 err ${JSON.stringify(err)}`)
        throw err
      })

    return rtn

  },
  async getBookingsV1(deviceId: string) : Promise<any> {

    console.log(`getBookingsV1 deviceId ${deviceId}`)

    const rtn = await resourceClient.get(`/v1/devices/${deviceId}/passwords/offline`)
      .catch(err => {
        console.log(`getBookingsV1 err ${JSON.stringify(err)}`)
        throw err;
      })

    console.log(`getBookingsV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async getBookingsV2(clientId: string, deviceId: string) : Promise<any> {

    console.log(`getBookingsV2 clientId ${clientId} deviceId ${deviceId}`)

    const rtn = await resourceClient.get(`/v2/${clientId}/${deviceId}/offline`)
      .catch(err => {
        console.log(`getBookingsV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`getBookingsV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async placeBookingV1(params: OfflineItem) : Promise<any> {

    const rtn = await resourceClient.post(`/v1/devices/${params.deviceId}/passwords/offline`, {effectiveTime: params.effectiveTime, invalidTime: params.invalidTime})
      .catch(err => {
        console.log(`placeBookingV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`placeBookingV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async placeBookingV2(clientId: string, params: OfflineItem) : Promise<any> {

    const rtn = await resourceClient.post(`/v2/${clientId}/${params.deviceId}/offline`, {effectiveTime: params.effectiveTime, invalidTime: params.invalidTime})
      .catch(err => {
        console.log(`placeBookingV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`placeBookingV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async getDynamicPasswordV1(deviceId: string) : Promise<any> {

    const rtn = await resourceClient.get(`/v1/devices/${deviceId}/passwords/dynamic`)
      .catch(err => {
        console.log(`getDynamicPasswordV1 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`getDynamicPasswordV1 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async getDynamicPasswordV2(clientId: string, deviceId: string) : Promise<any> {

    const rtn = await resourceClient.get(`/v2/${clientId}/${deviceId}/dynamic`)
      .catch(err => {
        console.log(`getDynamicPasswordV2 err ${JSON.stringify(err)}`)
        throw err
      })

    console.log(`getDynamicPasswordV2 ${JSON.stringify(rtn)}`)

    return rtn

  },
  async refreshDevicesV1() : Promise<any> {

    const rtn = await resourceClient.post(`/v1/clients/devices`)
      .catch(err => {
        console.log(`refreshDevicesV1 err ${JSON.stringify(err)}`)
        throw err
      })

    return rtn

  },
  async refreshDevicesV2(clientId: string) : Promise<any> {

    const rtn = await resourceClient.post(`/v2/clients/${clientId}/devices`)
      .catch(err => {
        console.log(`refreshDevicesV2 err ${JSON.stringify(err)}`)
        throw err
      })

    return rtn

  },
}
