
import Vue from 'vue'
import FlagIcon from 'vue-flag-icon'
import { mapState, mapActions } from 'vuex'

Vue.use(FlagIcon)

export default Vue.extend({
  name: 'App',

  data: () => ({
    languages: [
      'gb', 'jp'
    ],
    showMenu: false
  }),
  computed: {
     items() {
      if (this.username) {
        return [
          { title: 'Logout', href: `${process.env.VUE_APP_AUTH_URL}/logout?client_id=${this.client?.clientId}&logout_uri=${location.origin}/logout` }
          // { title: 'Logout', href: '/logout' }
        ]
      } else {
        return [
        ]
      }
     },
     currentUri() {
      return location.origin;
     },
    ...mapState({
      username: (state: string) => state.auth.username,
      client: (state: string) => state.auth.client,
    }),
  },
  methods: {
    async menuClicked(route: string) {

      if (this.username) {
        console.log('username', this.username)
        this.$router.push({name: route})  
      } else {
        console.log('no username', this.username)
        this.$router.push({name: route})  
      }
      
    },
    switchLocale (item) {
      console.log('switchLocale', item)
      this.$i18n.locale = item
      this.saveLocale(item)
    },
    ...mapActions({
      saveLocale: 'saveLocale'
    })
  }
})
