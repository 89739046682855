import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import clients from './modules/clients'
import devices from './modules/devices'
import passwords from './modules/passwords'
import auth from './modules/auth'
import users from './modules/users'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => {
    return {
      auth: {
        accessToken: state.auth.accessToken,
        refreshToken: state.auth.refreshToken,
        username: state.auth.username,
        userGroup: state.auth.userGroup,
        client: state.auth.client
      },
      locale: state.locale
    }
  }
})

export default new Vuex.Store({
  modules: {
    auth,
    clients,
    devices,
    passwords,
    users
  },
  plugins: [vuexLocal.plugin],
  state: {
    locale: 'jp'
  },
  mutations: {
    setLocale(state, val) {
      state.locale = val
    },
  },
  actions: {
    saveLocale ({ commit, state }, val) {
      commit('setLocale', val)
    },
  }
})
