import smartlockApi from '@/api/smartlock'

const state = {
    devices: []
}

const mutations = {
    setDevices(state, val) {
      state.devices = val
    },
}


const actions = {
  async listDevices ({ commit, state, rootState }, filters) {
    console.log('listDevices userGroup', rootState.auth.userGroup)
    console.log('listDevices filters', filters)

    let rtnService

    if (rootState.auth.userGroup == 'owner') {
      rtnService = await smartlockApi.listDevicesV2(rootState.auth.client.clientId)
    } else {
      rtnService = await smartlockApi.listDevicesV1()
    }

    let rtn

    if (filters) {
      rtn = rtnService.data.filter(val => {
        return filters.map(filter => {
          if (val[filter]) {
            return true
          } else {
            return false
          }
        }).reduce((previousValue, currentValue) => {
          return previousValue && currentValue
        }, true)
      })
    } else {
      rtn = rtnService.data
    }

    console.log('listDevices rtn', rtn)

    commit('setDevices', rtn)
  },
  updateDevices ({ commit, state }, val) {
    console.log('updateDevices val', val)

    const devices = state.devices.map(device => {
      if (device.deviceId == val.deviceId) {
        return val
      } else {
        return device
      }
    })

    console.log('updateDevices devices', devices)

    commit('setDevices', devices)
    
  },
  async refreshDevices ({ commit, rootState, dispatch }) {

    console.log('refreshDevices userGroup', rootState.auth.userGroup)

    if (rootState.auth.userGroup == 'owner') {
      await smartlockApi.refreshDevicesV2(rootState.auth.client.clientId)
    } else {
      await smartlockApi.refreshDevicesV1()
    }

    await dispatch('listDevices').catch(e => {
      console.error('listDevice error', e)
      throw e
    })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

