import smartlockApi from '@/api/smartlock'

const state = {
    clients: []
}

const mutations = {
  setClients(state, val) {
    state.clients = val
  }
}


const actions = {
  async addClient ({ commit, state }, {deviceId, clientName, tuyaAccount, isPublic}) {
    console.log('clients/addClient', {deviceId, clientName, tuyaAccount, isPublic})

    await smartlockApi.addClientV2(deviceId, clientName, tuyaAccount, isPublic)

  },
  async listClients ({ commit, state }) {

    const rtn = await smartlockApi.listClientsV2()

    commit('setClients', rtn.data)
  },
  async getClient ({ commit, state }, {clientId}) {

    const rtn = await smartlockApi.getClientV1(clientId)

    commit('setClients', [rtn.data])
  },
  async deleteClient ({ commit, state }, {clientId}) {

    await smartlockApi.deleteClientV2(clientId)
  }
}


const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
