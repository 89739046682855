import smartlockApi from '@/api/smartlock'
import { OfflineItem } from '@/model/model'
import moment from 'moment'

const state = {
  bookings: []
}

const mutations = {
  setBookings(state, val) {
    state.bookings = val
  }
}


const actions = {
  async removeInvalidBookings ({ commit, dispatch, rootState }, {deviceId}) {

    let rtn

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.deleteOfflineV2(rootState.auth.client.clientId, deviceId)
    } else {
      rtn = await smartlockApi.deleteOfflineV1(deviceId)
    }

    console.log('removeInvalidBookings rtn', rtn)

    if (rtn?.data?.msg) {
      throw new Error(rtn?.data?.msg)
    }

    const result = await dispatch('loadBookings', {deviceId: deviceId}).catch(e => {
      console.error('loadBookings error', e)
      throw e
    })

  },
  async loadBookings ({ commit, rootState }, {deviceId}) {

    let rtn

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.getBookingsV2(rootState.auth.client.clientId, deviceId)
    } else {
      rtn = await smartlockApi.getBookingsV1(deviceId)
    }

    console.log('loadBookings', rtn.data)

    const data = rtn.data.map((booking: any) => {
      booking.effectiveTime = moment.unix(booking.effectiveTime).format('LLL')
      booking.invalidTime = moment.unix(booking.invalidTime).format('LLL')
    })

    commit('setBookings', rtn.data)
  },
  async placeBooking ({ commit, rootState, dispatch }, params: OfflineItem) {

    let rtn

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.placeBookingV2(rootState.auth.client.clientId, params)
    } else {
      rtn = await smartlockApi.placeBookingV1(params)
    }

    console.log('placeBooking rtn', rtn)

    if (rtn?.data?.msg) {
      throw new Error(rtn?.data?.msg)
    }

    const result = await dispatch('loadBookings', {deviceId: params.deviceId}).catch(e => {
      console.error('loadBookings error', e)
      throw e
    })

  },
  async getDynamicPassword ({ commit, rootState, dispatch }, {deviceId}) {

    let rtn

    if (rootState.auth.userGroup == 'owner') {
      rtn = await smartlockApi.getDynamicPasswordV2(rootState.auth.client.clientId, deviceId)
    } else {
      rtn = await smartlockApi.getDynamicPasswordV1(deviceId)
    }

    
    return rtn.data?.result?.dynamic_password || `${rtn.data?.code}:${rtn.data?.msg}`

  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

