const messages = {
  gb: {
    home: {
      btn_login_tenant: 'Tenant Login',
      btn_login_guest: 'Guest Login',
      btn_login_user: 'User Login',
      btn_login_owner: 'Owner Login',
      label_tenant_code: 'Tenant Code',
      label_owner_code: 'Owner Code',
      label_required: 'Required'
    },
    guest: {
      label_device_list: 'Devices',
      table_col_device_name: 'Device Name',
      table_col_device_icon: 'Image',
      table_col_issue_dynamic: 'Issue Dynamic Password',
      table_col_dynamic_password: 'Dynamic Password',
      label_booking_list: 'Bookings',
      table_col_effective: 'Effective Time',
      table_col_invalid: 'Invalid Time',
      table_col_booking_password: 'Password'
    },
    clients: {
      label_tenant_list: 'Clients',
      table_col_delete_client: 'Delete',
      table_col_goto_devices: 'Go to Devices',
      table_col_goto_users: 'Go to Users',
      table_col_client_name: 'Client Name',
      table_col_client_id: 'Client ID',
      table_col_client_secret: 'Client Secret',
      table_col_tuya_account: 'Tuya Account',
      table_col_tuya_uid: 'Tuya UID'
    },
    devices: {
      label_device_list: 'Devices',
      label_user_list: 'Users',
      label_client: 'Client',
      label_password_config: 'Password Config',
      label_user_config: 'User Config',
      label_save_users: 'Save Users',
      label_refresh_devices: 'Refresh Devices',
      table_col_device_name: 'Device Name',
      table_col_device_id: 'Device ID',
      table_col_device_icon: 'Image',
      table_col_issue_dynamic: 'Issue Dynamic Password',
      table_col_dynamic_password: 'Dynamic Password',
      table_col_effective: 'Effective Time',
      table_col_invalid: 'Invalid Time',
      table_col_booking_password: 'Password',
      label_booking_list: 'Bookings',
      label_password_add: 'Add Booking',
      label_password_delete: 'Remove Invalid Bookings',
      label_from: 'From',
      label_to: 'To',
      table_col_username: 'User Name',
      table_col_device_ids: 'Devices'
    },
    users: {
      label_select_existing_users: 'Select Existing Users',
      label_add_new_users: 'Add New Users',
      label_save_new_users: 'Save New Users',
      label_remove_selected_users: 'Remove Selected Users',
      label_existing_users: 'Existing Users',
      label_new_users: 'New Users',
      table_col_username: 'User Name',
      table_col_roles: 'Roles',
      table_col_default_password: 'Password',
      table_col_actions: 'Actions',
    },
    menu: {
      gb: 'en',
      jp: 'jp'
    }
  },
  jp: {
    home: {
      btn_login_tenant: 'テナントログイン',
      btn_login_user: '利用者ログイン',
      btn_login_guest: 'ゲストログイン',
      btn_login_owner: '管理者ログイン',
      label_tenant_code: 'テナントコード',
      label_owner_code: 'オーナーコード',
      label_required: '必須項目'
    },
    guest: {
      label_device_list: '設備一覧',
      table_col_device_name: '設備名',
      table_col_device_icon: 'イメージ',
      table_col_issue_dynamic: '一時パスワード発行',
      table_col_dynamic_password: '一時パスワード',
      label_booking_list: 'パスワード一覧',
      table_col_effective: '開始',
      table_col_invalid: '終了',
      table_col_booking_password: '定時パスワード'
    },
    clients: {
      label_tenant_list: 'クライアント一覧',
      table_col_delete_client: '削除',
      table_col_goto_devices: 'デバイス一覧へ',
      table_col_goto_users: 'ユーザー一覧へ',
      table_col_client_name: 'Client 名',
      table_col_client_id: 'Client ID',
      table_col_client_secret: 'Client Secret',
      table_col_tuya_account: 'Tuya アカウント',
      table_col_tuya_uid: 'Tuya UID'
    },
    devices: {
      label_device_list: '設備一覧',
      label_user_list: 'ユーザー一覧',
      label_client: 'クライアント',
      label_password_config: 'パスワード設定',
      label_user_config: 'ユーザー設定',
      label_save_users: 'ユーザー保存',
      label_refresh_devices: 'デバイスリフレッシュ',
      table_col_device_name: '設備名',
      table_col_device_id: '設備ID',
      table_col_device_icon: 'イメージ',
      table_col_issue_dynamic: '一時パスワード発行',
      table_col_dynamic_password: '一時パスワード',
      table_col_effective: '開始',
      table_col_invalid: '終了',
      table_col_booking_password: '定時パスワード',
      label_booking_list: 'パスワード一覧',
      label_password_add: 'パスワード追加',
      label_password_delete: '古いパスワードクリア',
      label_from: '開始',
      label_to: '終了',
      table_col_username: 'ユーザー名',
      table_col_device_ids: '設備一覧'
    },
    users: {
      label_select_existing_users: '既存ユーザー選ぶ',
      label_add_new_users: 'ユーザー追加',
      label_save_new_users: 'ユーザー保存',
      label_remove_selected_users: '選んだユーザー削除',
      label_existing_users: '既存ユーザー',
      label_new_users: '新規ユーザー',
      table_col_username: 'ユーザー名',
      table_col_roles: 'ロール',
      table_col_default_password: 'パスワード',
      table_col_actions: 'アクション'
    },
    menu: {
      gb: '英',
      jp: '和'
    }
  }
}

export default messages