import smartlockApi from '@/api/smartlock'

const state = {
    accessToken: null,
    refreshToken: null,
    username: null,
    authUri: null,
    client: null,
    userGroup: null
}

const mutations = {
  setRefreshToken(state, val) {
    state.refreshToken = val
  },
  setAccessToken(state, val) {
    state.accessToken = val
  },
  setUsername(state, val) {
    state.username = val
  },
  setAuthUri(state, val) {
    state.authUri = val
  },
  setClient(state, val) {
    state.client = val
  },
  setUserGroup(state, val) {
    state.userGroup = val
  },
}


const actions = {
  async login ({ commit, state }, val) {

    const rtn = await smartlockApi.authorize(val.clientName, val.userGroup)

    window.open(rtn.data, "_self")
  },
  clearToken ({ commit, state }) {

    console.log('clearToken')

    commit('setRefreshToken', null)
    commit('setAccessToken', null)
    commit('setUsername', null)
    commit('setClient', null)
    commit('setUserGroup', null)

  },
  saveAccessToken ({ commit, state }, val) {

    commit('setAccessToken', val)
  },
  saveRefreshToken ({ commit, state }, val) {

    commit('setRefreshToken', val)
  },
  saveUsername ({ commit, state }, val) {

    commit('setUsername', val)
  },
  pickClient ({ commit, state }, val) {
    commit('setClient', val)
  },
  pickUserGroup({ commit, state }, val) {
    commit('setUserGroup', val)
  },
}


const getters = {
  getAccessToken: (state) => () => {
    return state.accessToken
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
