import axios from 'axios'

import store from '@/store'

export function axiosInterceptor(request) {

    // add auth header with jwt if account is logged in and request is to the api url
    const accessToken = store.getters['auth/getAccessToken']()
    // const isApiUrl = request.baseUrl.startsWith(process.env.API_URL)

    // console.log(`accessToken: ${accessToken}`)
    // console.log(`isApiUrl: ${isApiUrl}`)

    if (accessToken) {
        request.headers.common['Authorization'] = accessToken
        request.headers.common['Content-Type'] = 'application/json;charset=utf-8'
    }

    // console.log(`request: ${JSON.stringify(request)}`)

    return request
}
