import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../views/Clients.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('../views/Devices.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/guest',
    name: 'Guest',
    component: () => import('../views/Guest.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter(to, from, next) {
      store.dispatch('auth/clearToken')

      console.log(to);
      if (to.query.clientId && to.query.originHost) {
        console.log('window.location.href', `${process.env.VUE_APP_AUTH_URL}/logout?client_id=${to.query.clientId}&logout_uri=${to.query.originHost}/logout`);
        window.location.href = `${process.env.VUE_APP_AUTH_URL}/logout?client_id=${to.query.clientId}&logout_uri=${to.query.originHost}/logout`;  
      } else {
        window.location.href = '/'
      }
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {

    console.log('router.beforeEach', to)

    if (!store.state.auth.client || !store.state.auth.userGroup ) {
      console.log('router.beforeEach exit 1')

      return next({ name: 'Home', replace: true })
    }

    if (!store.state.auth.username || !store.state.auth.accessToken || !store.state.auth.refreshToken) {
      console.log('router.beforeEach exit 2')
      
      return next({ name: 'Home', replace: true })
    }

    return next()
  }

  return next()
})

export default router
